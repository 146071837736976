import {
  StarRating,
  Image,
  Link,
  lineClamp,
  Heading,
  Icon,
  Label,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import { ResponsiveStyleValue, SystemStyleObject } from '@styled-system/css';
import React, { memo, useMemo } from 'react';

import { DynamicPackageResult } from '@AuroraTypes';
import { useMapSearchResults } from '@Components/MapSearch/utils/useMapSearchResults';
import { PassengerPrice } from '@Components/Price/PassengerPrice';
import { RatingLink } from '@Components/RatingLink/RatingLink';
import { useAppContext } from '@Contexts/contexts';
import { useImageFallbackSrc } from '@Core/hooks/useImageFallbackSrc';
import { useDate } from '@Dates/useDateHook';
import { usePandaPageUrl } from '@Pages/search-results/usePandaPageUrl';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { useProductClickTracking } from '@Tracking';
import { slideUp } from '@UX/keyframes';

type SelectedOfferProps = {
  selectedOfferId: string | undefined;
};

function useOfferUrl(result?: DynamicPackageResult) {
  const { getPandaUrlForOffer } = usePandaPageUrl();

  const [departureAirports, filters, nights, rooms] = useSearchSelectionStore((state) => [
    state.departureAirports,
    state.filters,
    state.nights,
    state.rooms,
  ]);

  return useMemo(() => {
    if (!result) {
      return;
    }

    return getPandaUrlForOffer(result, {
      filters,
      departureAirports,
      nights,
      rooms,
      source: 'srp',
    });
  }, [departureAirports, filters, getPandaUrlForOffer, nights, result, rooms]);
}

export const SelectedOffer = memo<SelectedOfferProps>(({ selectedOfferId }) => {
  const { t } = useTranslation();
  const { localizedFormat } = useDate();
  const { isWebView } = useAppContext();
  const searchResults = useMapSearchResults();
  const offer = useMemo(
    () =>
      selectedOfferId
        ? searchResults.find((offer) => offer.hotel.accommodation.id === selectedOfferId)
        : undefined,
    [searchResults, selectedOfferId],
  );
  const imageFallbackSrc = useImageFallbackSrc();
  const trackProductClick = useProductClickTracking();
  const position = searchResults.findIndex((offerItem) => offerItem === offer) + 1;
  const url = useOfferUrl(offer);

  if (!offer || !url) {
    return null;
  }

  const { name, images, starRating, ratings } = offer.hotel.accommodation;
  const hotelImage = images.items[0];

  const onSelectedOfferClick = () => {
    trackProductClick(
      [
        {
          masterId: offer.hotel.accommodation.id,
          name,
          hasImages: !!hotelImage,
          price: offer?.pricing.total,
          position,
          refundablePackage: offer.badges.some(({ type }) => type === 'FREE_CANCELLATION'),
          isLuggageIncluded: offer.flights.luggage.isIncluded,
        },
      ],
      { list: 'MapSearch' },
    );
  };

  return (
    <div sx={{ animation: `${slideUp} .3s forwards`, willChange: 'transform' }}>
      <Link
        aria-label={t('searchResults.viewDeal')}
        href={url}
        target={!isWebView ? '_blank' : undefined}
        onClick={onSelectedOfferClick}
        sx={{
          position: 'fixed',
          left: ['3xs', '50%'],
          right: ['3xs', 'auto'],
          transform: [null, 'translateX(-50%)'],
          bottom: ['4xl', null, 'xs'],
          zIndex: 'stickyContent',
          display: 'grid',
          gridTemplateAreas: `"image content"`,
          gridTemplateColumns: '100px 1fr',
          gridColumnGap: '2xs' as ResponsiveStyleValue<SystemStyleObject>,
          backgroundColor: 'backgroundWhite',
          borderRadius: '8',
          width: [null, '496px'],
          padding: '3xs',
          textDecoration: 'none',
          boxShadow: 'elevationFixed',
        }}
      >
        <Image
          sx={{
            objectFit: 'cover',
            borderRadius: '8',
            gridArea: 'image',
          }}
          src={hotelImage?.url || imageFallbackSrc}
          alt={name}
          width={100}
          height={[125, 110, 110]}
        />

        <div sx={{ display: 'flex', flexDirection: 'column', gridArea: 'content' }}>
          {!!starRating && (
            <StarRating
              rating={starRating}
              sx={{ color: 'iconDefault' }}
            />
          )}

          <Heading
            as="h3"
            variant="smallbold"
            sx={{
              ...lineClamp(2),
              lineHeight: 1.2,
              marginTop: '4xs',
            }}
          >
            {name}
          </Heading>

          {ratings.map((rating) => (
            <RatingLink
              key={rating.id}
              provider={rating.provider}
              trackingAction="map-offer"
              reviewCount={rating.ratingCount}
              recommendation={rating.recommendation}
              rating={rating.rating}
              display="recommendation"
              floating
              sx={{ marginTop: '4xs' }}
            />
          ))}

          <div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              justifySelf: 'flex-end',
              flex: '1 0 auto',
            }}
          >
            <div sx={{ display: 'flex', flexDirection: 'column', marginTop: '3xs' }}>
              <Label
                variant="extrasmall"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'textDefault',
                }}
              >
                <Icon
                  name="Content/InputDate"
                  size="20"
                  sx={{
                    marginRight: '3xs',
                  }}
                />
                {localizedFormat(offer.hotel.checkInDate)}
                {' ∙ '}
                {t('nightsLabel', { count: offer.hotel.nights })}
              </Label>

              <div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  name="Content/PlaceAirport"
                  size="20"
                  color="iconDefault"
                  sx={{ marginRight: '3xs' }}
                />

                <Label
                  variant="extrasmall"
                  sx={{ color: 'textDefault' }}
                >
                  {t('flightsIncluded')}
                </Label>
              </div>
            </div>

            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PassengerPrice
                value={offer.pricing.total}
                suffixType="short"
                suffixStyles={{ fontSize: 'm' }}
                sx={{
                  fontSize: 'm',
                  fontWeight: 'bold',
                  color: 'textDefault',
                  textWrap: 'nowrap' as SystemStyleObject,
                }}
              />

              <Icon
                name="Actions/MoveNext"
                size="20"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '4xs',
                  color: 'iconDefault',
                }}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
});
